export enum UserRole {
  ADMIN = "ADM",
  BRANCH_MANAGER = "BM",
  MOD_OFFFICER = "MO",
  CUSTOMER_REPRESENTATIVE = "CRO",
  HEAD_OFFICER = "HO",
  CREDIT_OFFICER = "CO",
  BRANCH_MIS = "BMIS",
  BRANCH_ACCOUNTANT = "BACC",
  INSURANCE_OFFICER = "IO"
}
