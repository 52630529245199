export enum RoleName {
  ADMIN = "Admin",
  HEAD_OFFICER = "Head Officer",
  SUPER_ADMIN = "Super Admin",
  MIS_MANAGER = "MIS Manager",
  BRANCH_MANAGER = "Branch Manager",
  BRANCH_MIS = "Branch MIS",
  ACCOUNTANT = "Accountant",
  BRANCH_ACCOUNTANT = "Branch Accountant",
  MOD_OFFICER = "MOD Officer",
  INSURANCE_OFFICER = "Insurance Officer"
}
