import React, { useEffect, useState } from "react";
import { OptionArray } from "../../../../shared/types/optionArray.type";
import { Button, Checkbox, Col, Row, Spin } from "antd";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import CustomDocument from "../../../../shared/components/CustomDocument";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { Form, Formik } from "formik";
import "../loanIndividualDetails.scss";
import { CloseCircleOutlined } from "@ant-design/icons";
import EmptyDocument from "../../../../shared/components/EmptyDocument";
import { useNavigate, useParams } from "react-router-dom";
import customUploadIcon from "../../../../Assets/images/upload.png";
import useDrawer from "../../../../shared/hooks/useDrawer";
import CustomModal from "../../../../shared/components/CustomModal";
import { claimDocumentValidation } from "./uploadClaimDocumentValidation";
import DatePickerField from "../../../../shared/components/DatePickerField";
import { dateFormats } from "../../../../enums/dateFormats.type";
import { processClaimValidation } from "./processClaimValidation";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { ClaimsService } from "../../../../services/Claims/claims.service";
import { ClaimDocumentUpload, LoanClaimsModel, ProcessGroupClaim, ProcessIndividualClaim, ProcessMarkedClaim } from "../../../../models/loanClaims.model";
import {
  currencyFormatter,
  dateTimeFormatter,
} from "../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";
import { DateFormat } from "../../../../enums/dateFormat.type";
import { processMarkedClaimValidation } from "./processMarkedClaimValidation";
import SelectInput from "../../../../shared/components/SelectInput";
import claimsDocumentsUploadOptions from "../../../../shared/Constant/claimsDocumentUploadOptions";
import { ClaimsDocumentTypes } from "../../../../enums/claimsDocuments.type";
import insurerNameOptions from "../../../../shared/Constant/insurerNameOptions";
import Back from "../../../../shared/components/Back";
import moment, { Moment } from "moment";
import { toTitleCase } from "../../../../shared/utils/titleCaseConverter";
import { LoansClaimsStatus } from "../../../../enums/loanClaimsStatuses.type";
import { checkIsValidClaimDate } from "../../../../shared/utils/claimsDateUtil";


const IndividualLoanClaim = () => {
  const { loanId } = useParams<{ loanId: string }>();
  const { getIndividualClaimSummary, claimDocumentUpload } = ClaimsService();
  const [claim, setClaim] = useState(new LoanClaimsModel());
  const handleDocumentUpload = async (values: ClaimDocumentUpload) => {
    if(values.documentType === ClaimsDocumentTypes.DEATH_CERTIFICATE) {
     await claimDocumentUpload({
        claimId: claim?.id,
        deathCertificateId: values?.documentId
      })
    } else if (values.documentType === ClaimsDocumentTypes.OTHER_DOCUMENT) {
     await claimDocumentUpload({
        claimId: claim?.id,
        documentId: values?.documentId,
        documentName: values?.documentName
      })
    }
    uploadModalVisibility(false)
    initializeClaim()
  };

  const initializeClaim = async () => {
    setIsLoading(true);
    const claimDetails = await getIndividualClaimSummary(Number(loanId));
    claimDetails && setClaim(claimDetails);
    setIsLoading(false);
  };

  useEffect(() => {
    initializeClaim();
  }, [loanId]);

  const [isLoading, setIsLoading] = useState(false);
  const isProcessing = claim?.status === LoansClaimsStatus.PROCESSING;
  const isClaimed = claim?.status === LoansClaimsStatus.CLAIMED;
  const isInitiated = claim?.status === LoansClaimsStatus.INITIATED
  const navigate = useNavigate()

  const {
    visible: uploadModalVisible,
    toggleVisibility: uploadModalVisibility,
  } = useDrawer({});

  const {
    visible: processModalVisible,
    toggleVisibility: processModalVisibility,
  } = useDrawer({});

  const { visible: claimModalVisible, toggleVisibility: claimModalVisibility } =
    useDrawer({});

  const { processGroupClaims, processClaim, processMarkedClaim } = ClaimsService()

  const handleAttachmentClick = () => {
    uploadModalVisibility(true);
  };

  const handleProcessModalButtonClick = () => {
    if (isInitiated) {
      processModalVisibility(true);
    }
    if (isProcessing) {
      claimModalVisibility(true);
    }
  };

  const handleInitiateClaim = async (values: ProcessIndividualClaim) => {
    await processClaim({
      claimId: claim?.id,
      claimSentDate: values?.claimSentDate,
      insurerName: values?.insurerName
    })
    processModalVisibility(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleProcessClaim = async (values: ProcessMarkedClaim) => {
    await processMarkedClaim({
      claimAmount: values.claimAmount,
      claimId: claim.id,
      claimReceivedByCustomer: values.claimReceivedByCustomer ?? false,
      claimReceivedDate: values.claimReceivedDate
    })
    claimModalVisibility(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const claimDetails: OptionArray[] = [
    {
      label: "Name of Expired",
      value: fillEmptyData(claim?.nameOfExpired),
    },
    {
      label: "Role",
      value: fillEmptyData(toTitleCase(removeUnderscore(claim?.role))),
    },
    {
      label: "Date of Death",
      value: fillEmptyData(claim?.deathDate),
    },
    {
      label: "CRO Updated after",
      value: fillEmptyData(`${claim?.croUpdatedAfter} days`),
    },
    {
      label: "Insurer Name",
      value: fillEmptyData(toTitleCase(removeUnderscore(claim?.insurer))),
    },
    {
      label: "Nominee Name",
      value: fillEmptyData(toTitleCase(removeUnderscore(claim?.nomineeName))),
    },
    {
      label: "Outstanding Amount",
      value: fillEmptyData(
        currencyFormatter({ amount: claim?.outstanding, hasCurrencySign: true })
      ),
    },
    {
      label: "Dues Pending",
      value: fillEmptyData(claim?.numberOfDuesPending),
    },
    {
      label: "Amount in Wallet",
      value: fillEmptyData(
        currencyFormatter({
          amount: claim?.walletAmount,
          hasCurrencySign: true,
        })
      ),
    },
    {
      label: "Amount to Customer",
      value: fillEmptyData(
        currencyFormatter({
          amount: claim?.toCustomer,
          hasCurrencySign: true,
        })
      ),
    },
    {
      label: "Amount to Velicham",
      value: fillEmptyData(
        currencyFormatter({
          amount: claim?.toVelicham,
          hasCurrencySign: true,
        })
      ),
    },
    !isInitiated && {
      label: "Claim Sent Date",
      value: dateTimeFormatter(claim?.claimSentOn ?? "", DateFormat.DATE),
    },
    isClaimed && {
      label: "Claimed After",
      value: fillEmptyData(claim?.daysToClaim),
    },
  ].filter(Boolean) as OptionArray[];

  const checkIsValidProcessingDate = (date: Moment): boolean => {
    const claimSentOn = moment(claim.claimSentOn);
    return date.isSameOrBefore(claimSentOn, 'day');
};



const hasDeathCertificate = claim?.documents.some(document => document.documentName === ClaimsDocumentTypes.DEATH_CERTIFICATE);

  return (
    <div className="claims-section">
      <div className="card">
        {isLoading ? (
          <>
            <div className="text-center">
              <Spin />
            </div>
          </>
        ) : (
          <>
            <Row className="block">
            <Back/>
              <Col span={24} className="mb-5 mt-5">
                <Row>
                <Col className="block__heading">
                Claim Details
                  <span className={`loan-details__badge text-primary ml-5 mr-5 claims-status-badge ${isClaimed && "claimed-badge"}`}>
                    {removeUnderscore(claim?.status)}
                  </span>
                </Col>
                  {isClaimed && (
                    <Col className="customer-claim-status">
                      {claim?.claimReceivedByCustomer ? (
                        <p>Amount Received by Customer</p>
                      ) : (
                        <></>
                      )}
                    </Col>
                  )}
                  {claim?.status !== LoansClaimsStatus.CLAIMED && (
                    <Col className={`claims-button-container`}>
                      <Button
                        className="loan-disburse-btn ml-2 mr-2 claims-button"
                        disabled={!hasDeathCertificate}
                        onClick={handleProcessModalButtonClick}
                      >
                        {claim?.status === LoansClaimsStatus.PROCESSING
                          ? "Mark as Claimed"
                          : `Process Claim`}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
              {claimDetails?.map((detail) => {
                return (
                  <React.Fragment key={detail.label}>
                    <Col span={4} className="block__label mt-5">
                      <span> {detail.label} </span>
                      <div className="block__value text-capitalize">
                        <span>{detail.value}</span>
                      </div>
                      {detail?.label === "CRO Updated after" && (
                        <span className="cro-date">On {dateTimeFormatter(claim?.croUpdatedOn, DateFormat.DATE)}</span>
                      )}
                      {detail?.label === "Claimed After" && (
                        <span className="cro-date">On {dateTimeFormatter(claim?.claimedDate, DateFormat.DATE)}</span>
                      )}
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
            <Row>
              <Col span={24}>
                <Row gutter={[50,20]}>
                  <Col span={24} className="mb-5">
                    <span className="block__heading">Claim Documents</span>
                  </Col>
                  {!hasDeathCertificate && <Col span={8}> <EmptyDocument className={`empty-document__container ${!(claim?.documents.length > 0) && 'death-certificate__container'}`} documentText="Death Certificate to be uploaded"/></Col>}
                    {claim?.documents.map((document) => (
                      <Col span={5}  className={`${"claim-document__container" }`} key={document?.id}>
                        <CustomDocument
                          label={document?.getDocumentLabel()}
                          src={document?.frontSideImageUrl}
                          classname="claim-document__label"
                        />
                        {document?.createdAt && (
                          <span className={`${'ml-4'} cro-date`}>
                            Updated on {dateTimeFormatter(document?.createdAt, DateFormat.DATE)}
                          </span>
                        )}
                      </Col>
                    ))
                  }
                  {!isClaimed && !isProcessing && (
                    <Col span={6} className={`claims-document__upload-container ${claim?.documents.length > 0 && 'doc-holder'}`}>
                      <div
                        className="attachment-upload__input"
                        onClick={handleAttachmentClick}
                      >
                        <div>
                          <div className="attachment-upload__content">
                            {" "}
                            <img src={customUploadIcon} alt="" className="" />
                            <p>Upload Other Document</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                  <CustomModal
                    title="Upload Other Document"
                    visible={uploadModalVisible}
                    isOkButton={false}
                    isCancelButton={false}
                    onCancel={() => uploadModalVisibility(false)}
                    className="claim-upload-attachments-modal"
                  >
                    <Formik
                      initialValues={new ClaimDocumentUpload()}
                      onSubmit={handleDocumentUpload}
                      validationSchema={claimDocumentValidation}
                    >
                      {({
                        setFieldValue,
                        isValid,
                        dirty,
                        values,
                        resetForm,
                      }) => {
                        return (
                          <Form className="death-document-form">
                            <Col span={24}>
                            <SelectInput name="documentType" options={claimsDocumentsUploadOptions} placeholder="Select Document Type" />
                            </Col>
                            {values.documentType === ClaimsDocumentTypes.OTHER_DOCUMENT && (
                              <Col span={24} className="document-name-input">
                              <InputField name="documentName" placeholder="Enter Document Name" type={InputType.TEXT} />
                              </Col>
                            )}
                            <Col span={24}>
                              <AttachmentUpload
                                name="documentId"
                                placeholder="Upload"
                                accept="image/*, application/pdf"
                                setFieldValue={setFieldValue}
                              />
                              {values.documentId && (
                                <Col className="mt-5" span={24}>
                                  <span className="text-primary mr-5 text-center">
                                    File Uploaded
                                  </span>
                                  <CloseCircleOutlined
                                    onClick={() =>
                                      setFieldValue("documentId", undefined)
                                    }
                                  />
                                </Col>
                              )}
                            </Col>
                            <div className="drawer-footer text-right upload-modal-footer">
                              <Button
                                className="drawer-footer__cancel"
                                htmlType="reset"
                                onClick={() => uploadModalVisibility(false)}
                                type="link"
                              >
                                Cancel
                              </Button>
                              <Button
                                className="ml-5 drawer-footer__submit"
                                htmlType="submit"
                                disabled={!isValid || !dirty}
                              >
                                Upload
                              </Button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </CustomModal>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>

      <CustomModal
        title=""
        visible={processModalVisible}
        isOkButton={false}
        isCancelButton={false}
        className="process-claim-modal"
        onClose={() => processModalVisibility(false)}
      >
        <Formik
          initialValues={new ProcessGroupClaim()}
          onSubmit={handleInitiateClaim}
          validationSchema={processClaimValidation}
        >
          {({ setFieldValue, isValid, dirty, values, resetForm }) => {
            const handleModalCancel = () => {
              processModalVisibility(false);
              resetForm();
              navigate(0)            
            };

            return (
              <Form>
                <div>
                  <h1>Process Claim</h1>
                  <p>
                    Select the date on which you sent selected loan to claim
                  </p>
                  <DatePickerField
                    name="claimSentDate"
                    setFieldValue={setFieldValue}
                    format={dateFormats.DATE_FIRST}
                    type="date"
                    disabledDate={checkIsValidClaimDate}
                  
                  />
                 <SelectInput name="insurerName" options={insurerNameOptions} placeholder="Select an Insurer"/>
                  <div className="modal__footer text-right">
                    <Button
                      htmlType="reset"
                      onClick={handleModalCancel}
                      className="modal-footer__cancel"
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-footer__submit process-claims-submit-button"
                      htmlType="submit"
                      disabled={!dirty || !isValid}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CustomModal>
      <CustomModal
        title=""
        visible={claimModalVisible}
        isOkButton={false}
        isCancelButton={false}
        className="process-claim-modal"
        onClose={() => claimModalVisibility(false)}
      >
        <Formik
          initialValues={new ProcessMarkedClaim()}
          onSubmit={handleProcessClaim}
          validationSchema={processMarkedClaimValidation}
        >
          {({ setFieldValue, isValid, dirty, values, resetForm }) => {
            const handleModalCancel = () => {
              claimModalVisibility(false);
              resetForm();
              navigate(0)
            };

            const handleCheckBoxClick = (value: boolean) => {
              setFieldValue("claimReceivedByCustomer", value);
            };
            
            return (
              <Form className="processing-claim-form">
                <div>
                  <h1>Mark as Claimed</h1>
                  <p>Claim Received Date</p>
                  <DatePickerField
                    name="claimReceivedDate"
                    setFieldValue={setFieldValue}
                    format={dateFormats.DATE_FIRST}
                    type="date"
                    disabledDate={checkIsValidProcessingDate}
                  />
                  <p>Amount Received by the Customer from Claim</p>
                  <InputField
                    name="claimAmount"
                    placeholder="Enter Amount"
                    type={InputType.NUMBER}
                  />
                  <Checkbox
                    name="claimReceivedByCustomer"
                    onChange={(e) => handleCheckBoxClick(e.target.checked)}
                    checked={values?.claimReceivedByCustomer}
                  >
                    Claim Received by Customer
                  </Checkbox>
                  <div className="modal__footer text-right">
                    <Button
                      htmlType="reset"
                      onClick={handleModalCancel}
                      className="modal-footer__cancel"
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-footer__submit process-claims-submit-button"
                      htmlType="submit"
                      disabled={!dirty || !isValid}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </CustomModal>
    </div>
  );
};

export default IndividualLoanClaim;
